/* eslint-disable */
import instance from "@/services/instance.js";

//have to change instANCE
const CreateAdmin = (payload) => {
  return instance.instanceToken
    .post("/admin", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const MasterAdmin = (payload) => {
  return instance.instance
    .post("/admin/master", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const GetAllAdmin = (payload) => {
  return instance.instanceToken
    .get("/admin/all", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const SetChangePWd = (payload) => {
  return instance.instanceToken
    .put("/admin/changePassword", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const SetEnable = (payload) => {
  return instance.instanceToken
    .put("/admin/enable", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const SetDelete = (payload) => {
  return instance.instanceToken
    .post("/admin/delete", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const SetFeature = (payload) => {
  return instance.instanceToken
    .post("/admin/set_feature", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const GetFeature = (payload) => {
  return instance.instanceToken
    .get(`/admin/get_features/${payload}`)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};

const GetBookingRequests = (payload) => {
  if (!payload) {
    payload = "all";
  }
  return instance.instanceToken
    .get(`/bookings/admin-all/${payload}`)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};
const UpdateEventBooking = (payload) => {
  return instance.instanceToken
    .post("/bookings/createEventBooking", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};
const UpdateMemberBooking = (payload) => {
  return instance.instanceToken
    .post("/bookings/createMemberBooking", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {});
};
const ImageUpload = payload => {
  return instance.instanceToken
    .post("/image", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};
const bookingUpload = payload => {
  return instance.instanceToken
    .post("/image/booking-resources", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};
const DeleteBooking = (id) => {
  return instance.instanceToken
    .delete(`/bookings/deleteBooking/${id}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.error("Error deleting booking:", error);
      throw error;
    });
  };

export default {
  createAdmin: CreateAdmin,
  masterAdmin: MasterAdmin,
  getAllAdmin: GetAllAdmin,
  setChangePWd: SetChangePWd,
  setEnable: SetEnable,
  setDelete: SetDelete,
  setFeature: SetFeature,
  getFeature: GetFeature,
  getBookingRequests:GetBookingRequests,
  updateEventBooking:UpdateEventBooking,
  updateMemberBooking:UpdateMemberBooking,
  uploadLogo:ImageUpload,
  deleteBooking:DeleteBooking,
  bookingUpload:bookingUpload
};
